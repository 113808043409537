'use server';
import throwError from '@haaretz/s-common-utils/throwError';
import getFeatureLiveBlogItemId from '@haaretz/s-navigation-utils/getFeatureLiveBlogItemId';
import { getArticleId } from '@haaretz/s-server-context/articleId';
import { fetchLiveBlogItemsServerQuery } from '@haaretz/s-server-queries/LiveBlogItems.server';
import React from 'react';

import LiveBlogItem from 'pkgs/ds-organisms/s-live-blog/src/components/LiveBlogItem';

import type { LiveBlogItemFragment } from '@haaretz/s-fragments/LiveBlogItem';
import type { LiveBlogItemsServerQueryVariables } from '@haaretz/s-server-queries/LiveBlogItems.server';
import type { BaseServerActionParams } from '@haaretz/s-types';
interface DefaultResponse {
  html: JSX.Element[];
  firstItemId?: string;
  lastItemId?: string;
}

const defaultResponse: DefaultResponse = {
  html: [],
  firstItemId: undefined,
  lastItemId: undefined,
};

export default async function loadMoreLiveBlogItems({
  count,
  lastItemId,
  area,
  sort,
}: {
  lastItemId: LiveBlogItemsServerQueryVariables['lastItemId'];
  area: LiveBlogItemsServerQueryVariables['area'];
  sort: LiveBlogItemsServerQueryVariables['sort'];
  count?: LiveBlogItemsServerQueryVariables['count'];
} & BaseServerActionParams) {
  const articleId = getArticleId();

  if (!articleId) {
    throwError('Article id is required');

    return defaultResponse;
  }

  try {
    const data = await fetchLiveBlogItemsServerQuery({
      articleId,
      lastItemId,
      area,
      sort,
      count,
    })();

    if (!data.LiveUpdates?.items?.length) {
      return defaultResponse;
    }

    const clusterItems = (data.LiveUpdates?.items || []) as LiveBlogItemFragment[];

    const items = clusterItems.map((item, index) => {
      const featureItemId = getFeatureLiveBlogItemId();
      const isFeatureItem = featureItemId === item.itemId;
      const isItemWithoutBorder =
        !!featureItemId && !isFeatureItem && clusterItems[index - 1].itemId === featureItemId;
      return (
        <LiveBlogItem
          item={item}
          key={item.itemId}
          isFeatureItem={isFeatureItem}
          isItemWithoutBorder={isItemWithoutBorder}
        />
      );
    });

    return {
      html: items,
      firstItemId: clusterItems.at(0)?.itemId,
      lastItemId: clusterItems.at(-1)?.itemId,
    };
  } catch (error) {
    console.error(`Live blog items action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}
