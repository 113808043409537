'use client';

import fork from '@haaretz/l-fork.macro';
import Button from '@haaretz/s-button';
import throwError from '@haaretz/s-common-utils/throwError';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import loadMoreLiveBlogItems from '../../utils/loadMoreLiveBlogItems';
import FaviconBadge from '../../utils/loadMoreLiveBlogItems/FaviconBadge';
import { useLiveBlogActions, useLiveBlog } from '../LiveBlogProvider';

import type { Site, SiteName } from '@haaretz/s-types';

function LiveBlogStartItems() {
  const [itemsJSX, setItemsJSX] = React.useState<JSX.Element[]>([]);
  const [itemsJSXToDisplay, setItemsJSXToDisplay] = React.useState<JSX.Element[]>([]);
  const [isPending, startTransition] = React.useTransition();
  const searchParams = useSearchParams();
  const { firstItemId, order } = useLiveBlog();
  const { setFirstItemId } = useLiveBlogActions();

  React.useEffect(() => {
    setItemsJSX([]);
  }, [order]);

  const featureLiveBlogItem = searchParams.get('liveBlogItemId');

  const handleLoadMoreItems = React.useCallback(
    function handleLoadMore() {
      startTransition(async () => {
        try {
          const response = await loadMoreLiveBlogItems({
            lastItemId: firstItemId,
            area: 'top',
            postCacheParams: 'sa-live-blog-items',
            sort: order,
          });

          response.firstItemId && setFirstItemId(response.firstItemId);
          setItemsJSX(prevItems => [...response.html, ...prevItems]);
        } catch (error) {
          throwError(error as Error);
        }
      });
    },
    [firstItemId, order, setFirstItemId]
  );

  React.useEffect(() => {
    if (firstItemId === featureLiveBlogItem) {
      handleLoadMoreItems();
    }
  }, [handleLoadMoreItems, firstItemId, featureLiveBlogItem]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      handleLoadMoreItems();
    }, 10000); // NOTE: Every minute

    return () => clearInterval(interval);
  }, [handleLoadMoreItems]);

  function displayItems() {
    if (itemsJSX.length) {
      setItemsJSXToDisplay(prevItems => [...itemsJSX, ...prevItems]);
      setItemsJSX([]);
    }
  }

  const site: Site = (process.env.site as Site) || 'htz';
  const appName: SiteName = site;

  React.useEffect(() => {
    FaviconBadge(appName, itemsJSX.length);
  }, [appName, itemsJSX.length]);

  return (
    <div>
      {itemsJSX.length ? (
        <Button
          onClick={displayItems}
          {...(isPending
            ? { state: 'busy', busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }) }
            : { state: 'auto' })}
        >
          {fork({ default: 'טענו עוד', hdc: 'Load more' })} ({itemsJSX.length})
        </Button>
      ) : null}
      {itemsJSXToDisplay}
    </div>
  );
}

export default function LiveBlogStartItemsWrapper() {
  const { firstItemId, order } = useLiveBlog();

  if (!firstItemId || order === 'asc') return null;

  return <LiveBlogStartItems />;
}
